@import 'reset';
@import 'splide';
@import 'magnific';


body {
  background: rgb(24,24,24);
  background: linear-gradient(45deg, rgba(24,24,24,1) 0%, rgba(32,32,32,1) 50%, rgba(24,24,24,1) 100%);

  font-family: 'Raleway', sans-serif;
  color:white;
  .pattern-container {
    width:100%;
    height:100%;
    background-image  :url(../images/build/pattern.png);
  }
}

h1 {
  font-size:64px;
  text-transform:uppercase;
  text-align:center;
  font-weight:200;
  color:#e9d9ae;
  padding-top:24px;
  line-height:1.2em;
  letter-spacing:5px;
  background: linear-gradient(0deg, rgba(203,184,127,1) 0%, rgba(233,217,174,1) 50%, rgba(203,184,127,1) 100%);
  /* clip hackery */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-position: center 11px;
  @media(max-width:700px) {
    font-size:40px;
  }
}

h1 + .subtitle {
  font-size: 36px;
  font-weight: 200;
  line-height: 1.35em;
  text-align:center;
  letter-spacing: 2px;
  @media(max-width:700px) {
    font-size:24px;
  }
}

.menu {
  max-width:1080px;
  width:100%;
  margin:40px auto;

  ul {
    list-style:none;
    display:flex;
    justify-content:space-around;
    list-style:none;
    padding:0;
    margin:0;
    @media(max-width:800px) {
      flex-direction:column;
      align-items:center;
    }
    li {
      @media(max-width:800px) {
        margin-bottom:10px;
      }
      a {
        color:#f0e5c5;
        position:relative;
        font-size: 36px;
        font-weight: 200;
        text-transform:uppercase;
        line-height: 1.35em;
        text-decoration:none;
        padding-bottom:4px;
        display:table;
        transition:all 0.3s ease-out;
        background: linear-gradient(0, rgba(217,199,120,1) 0%, rgba(255,241,162,1) 50%, rgba(217,199,120,1) 100%);/* clip hackery */
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        letter-spacing: 4px;
        &:before {
          content:'';
          width:80%;
          left:10%;
          height:1px;
          position:absolute;
          bottom:20px;
          background:#dac979;
          opacity:0;
          transition:all 0.3s ease-out;

        }
        &:hover {
          color:#dac979;
          &:before {
            bottom:0;
            opacity:1;
          }
        }
      }
    }
  }
}

section {
  max-width:1440px;
  margin:40px auto;
  width:100%;
  background:rgba(255,255,2153,.04);
  background-blend-mode: multiply;
  &:last-of-type {
    margin-bottom:0;
  }
  h2 {
    font-weight:100;
    font-size:64px;
    letter-spacing:5px;
    text-transform:uppercase;
    text-align:center;
    color:#e7d7aa;
    padding-top: 32px;
    line-height: 1.2;
    background: linear-gradient(0deg, rgba(203,184,127,1) 0%, rgba(233,217,174,1) 50%, rgba(203,184,127,1) 100%);
    /* clip hackery */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media(max-width:600px) {
      font-size:40px;
    }
  }
  .subtitle {
    font-size: 36px;
    font-weight: 200;
    line-height: 1.35em;
    text-align:center;
    letter-spacing: 2px;
    margin:0 0 24px;
    @media(max-width:600px) {
      font-size:30px;
    }
  }
}

.splide {
  margin-bottom:60px;
  h3 {
    font-size: 24px;
    font-weight: 100;
    line-height: 1.35em;
    text-align:center;
    letter-spacing: 1px;
    margin:0 0 20px;
    @media(max-width:600px) {
      font-size:18px;
    }
  }
}

.splide__slide {
  transition:all 0.3s linear;
  // max-width:290px;
  display:flex;
  align-items:center;
  justify-content:center;
  &.is-active {
    // max-width: 488px;
    // width: 100% !important;
    img {
      @media(min-width:1000px) {
        transform:scale(1.6);
      }
    }
  }
  img {
    width:100%;
    height:auto;
    transition:all 0.3s ease-in-out;
  }
}
#portfolio {
  padding-bottom: 1px;
}

#about {
  img {
    margin:30px auto;
    display:block;
  }
  .desc {
    max-width: 1220px;
    margin: 30px auto;
    padding: 0 20px 20px;
    p {
      font-size: 24px;
      font-weight: 300;
      margin-bottom: 20px;
      text-align: center;
      letter-spacing: 2px;
      color:#fcf3da;
      line-height: 1.3em;
      @media(max-width:600px) {
        font-size:16px;
      }
    }
  }
}

.columns {
  display:flex;
  flex-wrap:wrap;
  justify-content:space-around;
  margin:40px 0 0;
  color:#fcf3da;
  padding-bottom:45px;
  .column {
    max-width:500px;
    text-align:center;
    width: 100%;
    margin: 0 20px;
    a {
      text-decoration:none;
    }
  }
}
a {
  color:#fcf3da;
}

.big {
  font-size:36px;
  font-weight:100;
  margin:15px 0;
  letter-spacing:2px;
  @media(max-width:600px) {
    font-size:22px;
  }
  &.strong {
    font-weight:600;
  }
}



.footer-text {
  color:#fcf3da;
  text-align:center;
  padding:30px 0;
  line-height:1;
  font-weight:200;
  font-size:18px;
  letter-spacing:2px;
}


.hr-line {
  max-width:640px;
  width:90%;
  display:table;
  margin:20px auto 35px;
  height:2px;
  background: rgb(122,116,91);
  background: linear-gradient(45deg, rgba(122,116,91,1) 0%, rgba(255,241,162,1) 50%, rgba(122,116,91,1) 100%);
  background-blend-mode: multiply;
}


.video-slider {
  .splide__slide {
    position:relative;
    &:before {
      content: "";
      width: 100px;
      height: 90px;
      background: url(../images/source/yt_play.png);
      background-size: contain;
      background-repeat: no-repeat;
      top: calc(50% - 45px);
      left: calc(50% - 50px);
      position: absolute;
      z-index: 10;
      pointer-events: none;
    }
  }
}
