@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
.splide__container {
  position: relative;
  box-sizing: border-box;
}
.splide__list {
  margin: 0 !important;
  padding: 0 !important;
  width: -webkit-max-content;
  width: max-content;
  will-change: trasform;
}
.splide.is-active .splide__list {
  display: flex;
}
.splide__pagination {
  display: inline-flex;
  align-items: center;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
}
.splide__pagination li {
  list-style-type: none;
  display: inline-block;
  line-height: 1;
  margin: 0;
}
.splide {
  visibility: hidden;
}
.splide,
.splide__slide {
  position: relative;
  outline: none;
}
.splide__slide {
  box-sizing: border-box;
  list-style-type: none !important;
  margin: 0;
  flex-shrink: 0;
}
.splide__slide img {
  vertical-align: bottom;
}
.splide__slider {
  position: relative;
}
.splide__spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #999;
  border-left-color: transparent;
  animation: splide-loading 1s linear infinite;
}
.splide__track {
  position: relative;
  z-index: 0;
  overflow: hidden;
  margin: 0 155px;
  @media(max-width:600px) {
    margin:0 40px;
  }
}
.splide--draggable > .splide__track > .splide__list > .splide__slide {
  -webkit-user-select: none;
  user-select: none;
}
.splide--fade > .splide__track > .splide__list {
  display: block;
}
.splide--fade > .splide__track > .splide__list > .splide__slide {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
}
.splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
  position: relative;
  z-index: 1;
  opacity: 1;
}
.splide--rtl {
  direction: rtl;
}
.splide--ttb > .splide__track > .splide__list {
  display: block;
}
.splide--ttb > .splide__pagination {
  width: auto;
}
.splide__arrow {
  position: absolute;
  z-index: 1;
  top: calc(50% + 1.5rem);
  transform: translateY(-50%);
  width: 3.5em;
  height: 3.5em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  background:url(../images/build/arrow.png);
  background-size:contain;
  background-repeat:no-repeat;
  @media(max-width:600px) {
    width: 2em;
  height: 2em;
  }
}
.splide__arrow svg {
display:none;
}
.splide__arrow:hover {
  cursor: pointer;
  opacity: 0.9;
}
.splide__arrow:focus {
  outline: none;
}
.splide__arrow--prev {
  left: 4em;
  @media(max-width:600px) {
    left:0.5em;
  }
}
.splide__arrow--prev svg {
  transform: scaleX(-1);
}
.splide__arrow--next {
  right: 4em;
  transform: rotate(180deg) translateY(50%);
  @media(max-width:600px) {
    right:0.5em;
  }
}
.splide__pagination {
  position: absolute;
  z-index: 1;
  bottom: 0.5em;
  left: 50%;
  transform: translateX(50%);
  padding: 0;
}
.splide__pagination__page {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #ccc;
  border-radius: 50%;
  margin: 3px;
  padding: 0;
  transition: transfrom 0.2s linear;
  border: none;
  opacity: 0.7;
}
.splide__pagination__page.is-active {
  transform: scale(1.4);
  background: #fff;
}
.splide__pagination__page:hover {
  cursor: pointer;
  opacity: 0.9;
}
.splide__pagination__page:focus {
  outline: none;
}
.splide__progress__bar {
  width: 0;
  height: 3px;
  background: #ccc;
}
.splide--nav > .splide__track > .splide__list > .splide__slide {
  border: 3px solid transparent;
}
.splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
  border-color: #000;
}
.splide--nav > .splide__track > .splide__list > .splide__slide:focus {
  outline: none;
}
.splide--rtl > .splide__arrows .splide__arrow--prev,
.splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev {
  right: 1em;
  left: auto;
}
.splide--rtl > .splide__arrows .splide__arrow--prev svg,
.splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev svg {
  transform: scaleX(1);
}
.splide--rtl > .splide__arrows .splide__arrow--next,
.splide--rtl > .splide__track > .splide__arrows .splide__arrow--next {
  left: 1em;
  right: auto;
}
.splide--rtl > .splide__arrows .splide__arrow--next svg,
.splide--rtl > .splide__track > .splide__arrows .splide__arrow--next svg {
  transform: scaleX(-1);
}
.splide--ttb > .splide__arrows .splide__arrow,
.splide--ttb > .splide__track > .splide__arrows .splide__arrow {
  left: 50%;
  transform: translate(-50%);
}
.splide--ttb > .splide__arrows .splide__arrow--prev,
.splide--ttb > .splide__track > .splide__arrows .splide__arrow--prev {
  top: 1em;
}
.splide--ttb > .splide__arrows .splide__arrow--prev svg,
.splide--ttb > .splide__track > .splide__arrows .splide__arrow--prev svg {
  transform: rotate(-90deg);
}
.splide--ttb > .splide__arrows .splide__arrow--next,
.splide--ttb > .splide__track > .splide__arrows .splide__arrow--next {
  top: auto;
  bottom: 1em;
}
.splide--ttb > .splide__arrows .splide__arrow--next svg,
.splide--ttb > .splide__track > .splide__arrows .splide__arrow--next svg {
  transform: rotate(90deg);
}
.splide--ttb > .splide__pagination {
  display: flex;
  flex-direction: column;
  bottom: 50%;
  left: auto;
  right: 0.5em;
  transform: translateY(50%);
}
